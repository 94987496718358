.app {
  padding-top: 56px;
}

.content {
  margin-top: 80px;
}

.navbar-brand {
  font-family: 'Open Sans', 'Merriweather', serif;
  font-size: 20px;
  font-weight: bold;
}

.navbar-brand .logo {
  margin-left: 4px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

p {
  text-align: justify;
  text-justify: distribute;
}

.editor > .splitter-layout {
  top: 56px;
  height: calc(100vh - 56px);
}

.project-space {
  margin: 5px 10px;
}

.model-wrapper {
  margin: 5px;
}

.model-wrapper .row {
  margin: 0;
}

.model-wrapper .code-title {
  padding-left: 0;
  font-weight: bold;
}

.model-wrapper textarea {
  color: #616161;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  overflow: auto;
  height: calc(100vh - 112px);
  width: 100%;
  resize: none;
  outline: none !important;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.model-wrapper .gen-container {
  overflow: auto;
  height: calc(100vh - 112px);
  width: 100%;
}
